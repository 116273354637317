import React from "react"
import Countdown from "./CountDown"
import { Button, FilledInput, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, Stack, TextField } from "@mui/material"
import { styled } from '@mui/material/styles'
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup";
import zxcvbn from "zxcvbn";
import { yupResolver } from '@hookform/resolvers/yup';
import classes from "./ChangePassword.module.scss"
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


const CustomFormControl = styled(FormControl)(({ theme, error }) => ({
    '& .MuiFilledInput-root': {
        fontSize: '16px',
        borderRadius: '6px',
        border: '1px solid #CDCFF9',
        overflow: 'hidden',
        backgroundColor: "#F3F4FF!important",
        fontFamily: "Nunito",
        ...(error && ({
            border: '1px solid red',
        })),
        '& .MuiFilledInput-input': {
            '&:hover, &:focus': {
                boxShadow: 'none',
            },
        },

    },
    '& .MuiInputLabel-filled': {
        fontFamily: "Nunito",
    }

}))
const ButtonCustomContained = styled(Button)({
    border: ' 2px solid #5A60EC',
    '&.MuiButton-contained': {
        color: '#ffffff',
        textTransform: 'none',
        fontWeight: '800',
        fontSize: '17px',
        borderRadius: '50px',
        backgroundColor: '#5A60EC',
        padding: '7px 21px',
    },
    '&:hover, &:focus': {
        boxShadow: 'none',
        border: ' 2px solid #5A60EC',
        backgroundColor: '#5A60EC',
    },
})

const ButtonCustomOutlined = styled(Button)({
    border: ' 2px solid #5A60EC',
    '&.MuiButton-outlined': {
        color: '#5A60EC',
        textTransform: 'none',
        fontWeight: '800',
        fontSize: '17px',
        borderRadius: '50px',
    },
    '&:hover, &:focus': {
        boxShadow: 'none',
        border: ' 2px solid #5A60EC',
        backgroundColor: 'transparent',
    },
})

const schema = () =>
    yup.object().shape({
        newPassword: yup.string().required('Please enter a new password.')
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/, 'The password must contain 8 characters, at least one letter and one number')
            .test('password-strength', 'Password is too weak', value => zxcvbn(value).score >= 1),
        verifyNewPassword: yup.string().required('Please confirm your password.')
            .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
    })

const ForgotPassword: React.FC<{
    startTime: boolean
    guid: undefined
    forgotPasswordresendCode: (type: any) => void
    currentEmail: string
    setIsForgotPassword: React.Dispatch<React.SetStateAction<boolean>>
}> = (props) => {
    const [valOTP, setValOTP] = React.useState("")
    const [passwordStrength, setPasswordStrength] = React.useState(null);
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showVerifyPassword, setShowVerifyPassword] = React.useState(false);
    const [isResetPassword, setIsResetPassword] = React.useState(false)
    const [rid, setRid] = React.useState("")
    const {
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm<any>({
        resolver: yupResolver(schema()),
        mode: 'all'
    })
    const verifyOTP = (type) => {

        fetch(`/parentaccount/verifytoken`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Id: props.guid,
                Otp: valOTP,
                ResetPassword: type == "resetOTP"
            })
        }).finally()
            .then(response => response.json())
            .then(data => {
                if (data && data.IsValid) {
                    setIsResetPassword(true)
                    setRid(data.ResetId)
                    document.getElementById(type + 'Error').style.display = "none"
                    document.getElementById('resetPasswordPage').style.display = "block"
                } else {
                    document.getElementById(type + 'Error').style.display = "block"
                    document.getElementById('resetPasswordPage').style.display = "none"
                }
            })
            .catch(error => {
                console.error(error);
            });
    }



    const resetPassword = (values: any) => {
        const payload = {
            resetId: rid,
            email: props.currentEmail,
            password: values.newPassword
        }

        fetch(`/parentaccount/resetpassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        }).finally()
            .then(response => response.json())
            .then(data => {
                if (data) {
                    props.setIsForgotPassword(false)
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    const checkPasswordStrength = (password) => {
        const result = zxcvbn(password);
        if (!password) {
            setPasswordStrength(undefined);
        } else {
            setPasswordStrength(result.score);

        }
    };

    const onPasswordChange = (event) => {
        const newPassword = event.target.value;
        checkPasswordStrength(newPassword);
        setValue('newPassword', newPassword);
    };
    return (
        <>
            <div className={`${classes.pageContentContainer} mt-[40px] lg:mt-0`}>
                <div className="container mx-auto">
                    <div className="flex flex-col justify-between my-8 px-4 sm:px-6 lg:px-20 xl:px-24">
                        {!isResetPassword ? <div id="checkEmailPage">
                            <div className="text-2xl font-bold mb-8 text-[#272727]" style={{
                                fontWeight: 800, fontSize: '26px'
                            }}>Forgot Password - Verify Your Account</div>
                            <div className="text-[#5A5A5A] mb-4" style={{ color: '#5a5a5a', fontWeight: 500, fontSize: '16px' }}>
                                <div className="mb-4">We have sent a One Time Verification Code to <span className="displayEmail font-semibold">{props.currentEmail}</span>.</div>
                                <div className="mb-4">Please enter it below to verify account:</div>
                                <div id="resetOTPCount" className="mb-4 flex justify-center font-semibold text-[#5A5A5A]">
                                    <Countdown startTime={props.startTime} key={props.guid} />
                                </div>
                                <form method="post">
                                    <CustomFormControl fullWidth>
                                        <TextField
                                            label="OTP Code"
                                            type={'text'}
                                            value={valOTP}
                                            variant="filled"
                                            onChange={(event) => setValOTP(event.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                        />
                                    </CustomFormControl>

                                    <p id="resetOTPError" className="hidden font-semibold text-[#ED033B]">
                                        Oops the code is incorrect try again
                                    </p>
                                    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ marginTop: '20px' }} gap={'10px'}>
                                        <ButtonCustomOutlined variant="outlined" size="large" onClick={() => props.setIsForgotPassword(false)}>
                                            Cancel
                                        </ButtonCustomOutlined>
                                        <ButtonCustomContained variant="contained" size="large" onClick={() => verifyOTP('resetOTP')}>
                                            Verify
                                        </ButtonCustomContained>
                                    </Stack>
                                </form>
                                <div className="mt-3 w-full flex justify-center">
                                    <button id="resetOTPResend"
                                        onClick={() => {
                                            props.forgotPasswordresendCode('resetOTP')
                                            document.getElementById('resetOTPError').style.display = "none"
                                        }}
                                        className="text-center text-berry hover:text-darkberry font-bold">
                                        <u>Resend code</u>
                                    </button>
                                </div>
                            </div>
                        </div> : <div id="resetPasswordPage">
                            <h2 className="text-2xl font-bold mb-8 text-[#272727] text-center">Reset password</h2>
                            <form onSubmit={handleSubmit(resetPassword)}>
                                <Controller control={control} name="newPassword" render={({ field }) => {
                                    return (
                                        <CustomFormControl fullWidth variant="filled" error={!!errors.newPassword}>
                                            <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                                            <FilledInput
                                                {...field}
                                                id="outlined-adornment-password"
                                                type={showNewPassword ? 'text' : 'password'}
                                                disableUnderline
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowNewPassword(show => !show)}
                                                            edge="end"
                                                        >
                                                            {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                onChange={onPasswordChange}
                                            />
                                            <div className={classes.strengthMeter} id="strength-meter" >
                                                <div className={classes.strengthMeterFill} data-strength={passwordStrength}></div>
                                            </div>
                                            <FormHelperText
                                                error={!!errors.newPassword}
                                            >
                                                {errors.newPassword &&
                                                    `${errors.newPassword.message}`}
                                            </FormHelperText>
                                        </CustomFormControl>
                                    )
                                }} />
                                <Controller control={control} name="verifyNewPassword" render={({ field }) => {
                                    return (
                                        <CustomFormControl fullWidth variant="filled" error={!!errors.verifyNewPassword}>
                                            <InputLabel htmlFor="outlined-adornment-password">Verify New Password</InputLabel>
                                            <FilledInput
                                                {...field}
                                                id="outlined-adornment-password"
                                                type={showVerifyPassword ? 'text' : 'password'}
                                                disableUnderline
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowVerifyPassword(show => !show)}
                                                            edge="end"
                                                        >
                                                            {showVerifyPassword ? <Visibility /> : <VisibilityOff /> }
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            <FormHelperText error={!!errors.verifyNewPassword}>
                                                {errors.verifyNewPassword && `${errors.verifyNewPassword.message}`}
                                            </FormHelperText>
                                        </CustomFormControl>
                                    )
                                }} />
                                <div className={classes.buttonGroup}>
                                    <ButtonCustomOutlined variant="outlined" size="large" onClick={() => props.setIsForgotPassword(false)}>
                                        Cancel
                                    </ButtonCustomOutlined>
                                    <ButtonCustomContained variant="contained" size="large" type="submit">
                                        Save Changes
                                    </ButtonCustomContained>
                                </div>
                            </form>
                        </div>}
                    </div>
                </div>
            </div>
        </>



    )
}

export default ForgotPassword