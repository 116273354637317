import React, { useState, useEffect } from 'react';

const Countdown: React.FC<{
    startTime: boolean
}> = (props) => {
    const initialTime = 10 * 60;
    const [timeLeft, setTimeLeft] = useState(initialTime);
    const button = document.getElementById('resetOTPResendSaveData');
    const btnResetOTPResend = document.getElementById('resetOTPResend');

    useEffect(() => {

        if (timeLeft > 0 && props.startTime) {
            const intervalId = setInterval(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);


            return () => {
                clearInterval(intervalId);
            };
        }
    }, [timeLeft, props.startTime]);

    useEffect(() => {
        const handleButtonState = (buttonElement) => {
            if (!buttonElement) return;
            if (timeLeft > 0 && props.startTime) {
                buttonElement.setAttribute('disabled', 'disabled');
                buttonElement.classList.add('disabled:text-gray-400');
            } else {
                buttonElement.removeAttribute('disabled');
                buttonElement.classList.remove('disabled:text-gray-400');
            }
        };

        handleButtonState(button);
        handleButtonState(btnResetOTPResend);

    }, [timeLeft, props.startTime, button, btnResetOTPResend]);



    const formatTime = () => {
        const minutes = Math.floor(timeLeft / 60).toString().padStart(2, '0');
        const seconds = (timeLeft % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    return (
        <div>
            {timeLeft > 0 ? <span>{formatTime()}</span> : <span>Time's up!</span>}
        </div>
    );
};

export default Countdown;
